import React from 'react';
import {Link, useOutletContext} from "react-router-dom";
import "./_pagination.scss";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import {routes} from "../../routes/routes";

function Pagination({count, numAroundItems = 1, currentPage, limit = 12}) {
    const lang = useOutletContext();
    let numberPage = Math.ceil(count / limit);
    let aroundItems = numAroundItems * 2 + 1;
    let itemArray = Array(aroundItems).fill(startNumber());
    currentPage = parseInt(currentPage);

    function startNumber() {
        if (currentPage <= numAroundItems) {
            return 1;
        } else if (currentPage >= numberPage - numAroundItems) {
            return numberPage - numAroundItems * 2;
        } else {
            return currentPage - numAroundItems;
        }
    }

    return (
        <div className="pagination">
            <ul>
                <li><Link className="box"
                          to={`${routes.NEWS.realPath(lang)}?page=${currentPage - 1 < 1 ? 1 : currentPage - 1}&limit=${limit}`}><FaAngleLeft/></Link>
                </li>
                {itemArray.map((el, index) => {
                    return <li key={index}
                               className={parseInt(currentPage) === el + index ? "active" : ""}>
                        <Link className="box" to={`${routes.NEWS.realPath(lang)}?page=${el + index}&limit=${limit}`}>{el + index}</Link>
                    </li>;
                })}
                {
                    currentPage < numberPage - numAroundItems &&
                    (
                        <>
                            <li><span className="box">...</span></li>
                            <li><Link className="box"
                                      to={`${routes.NEWS.realPath(lang)}?page=${numberPage}&limit=${limit}`}>{numberPage}</Link></li>
                        </>
                    )
                }

                <li><Link className="box"
                          to={`${routes.NEWS.realPath(lang)}?page=${currentPage + 1 > numberPage ? numberPage : currentPage + 1}&limit=${limit}`}><FaAngleRight/></Link>
                </li>

            </ul>

        </div>
    );
}

export default Pagination;