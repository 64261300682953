import React from 'react';
import {Link} from "react-router-dom";
import {routes} from "../../../routes/routes";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function Error204() {
    const {t} = useTranslation();
    const {lang} = useSelector(state => state.configStore);
    return (
        <div className="errors-204">
            <h3>{t("errors.204.title")}</h3>
            <p>{t("errors.204.description")}</p>
            <Link className="btn btn-lg btn_rounded btn_rounded-primary"
                  to={routes.KONTAKT.realPath(lang)}>{t("errors.204.button")}</Link>
        </div>
    );
}

export default Error204;