import {Link} from "react-router-dom";
import {FaXmark} from "react-icons/fa6";
import {useState} from "react";
import {useSelector} from "react-redux";
import {routes} from "../../routes/routes.js";
import Accordion from "../Accordion/Accordion.jsx";
import {realFilePath} from "../../libs/utils";

function MobileMenu({toggle, state, menu}) {
    const {lang} = useSelector((state) => state.configStore);
    const [expanded, setExpanded] = useState([]);

    const handleMobilMenu = () => {
        toggle(false);
        resetMenu();
    };

    const resetMenu = () => {
        setExpanded([]);
        toggle(false);
    };

    return (
        <div className={`navbar_menu-mobile ${state && "expand"}`}>
            <div className="navbar_menu-mobile-header">
                <Link to={routes.HOME.path[lang]} onClick={resetMenu}>
                    <img src={realFilePath("logo.png")} alt="fim fakultet"/>
                </Link>
                <button onClick={handleMobilMenu}>
                    <FaXmark/>
                </button>
            </div>
            <div className="navbar_menu-mobile-body">
                {menu.map((item, key) => {
                    if (parseInt(item.drop)) {
                        return (
                            <Accordion
                                title={item.caption}
                                indexKey={key}
                                key={key}
                                expanded={expanded}
                                setExpanded={setExpanded}>
                                <SubMenu item={item.submenu} resetMenu={resetMenu}/>
                            </Accordion>
                        );
                    } else {
                        return (
                            <div className="accordion" key={key}>
                                <h4 className="accordion_title" key={key}>
                                    <Link to={item.to} onClick={resetMenu}>
                                        {item.caption}
                                    </Link>
                                </h4>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}

const SubMenu = ({item, resetMenu}) => {
    const [expanded, setExpanded] = useState([]);
    return item.map((subItem, key) => {
        if (parseInt(subItem.drop)) {
            return (
                <Accordion
                    title={subItem.caption}
                    indexKey={key}
                    key={key}
                    expanded={expanded}
                    setExpanded={setExpanded}>
                    <ul>
                        {subItem.submenu.map((ssubItem, sKey) => {
                            return (
                                <li key={sKey}>
                                    <Link onClick={resetMenu} to={ssubItem.to}>
                                        {ssubItem.caption}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </Accordion>
            );
        } else {
            return (
                <div className="accordion">
                    <h4 className="accordion_title" key={key}>
                        <Link to={subItem.to} onClick={resetMenu}>
                            {subItem.caption}
                        </Link>
                    </h4>
                </div>
            );
        }
    });
};

export default MobileMenu;
