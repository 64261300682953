import React, {useEffect} from 'react';
import {routes} from "../../routes/routes";
import {useOutletContext} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

function Journals(props) {
    const {lang, setHeroTitle} = useOutletContext();
    const dispatch = useDispatch();
    const {t} = useTranslation("locals");
    useEffect(() => {
        setHeroTitle(routes.IZDAVAC_CASOPIS.caption[lang]);
    }, [lang]);

    return (
        <section className="journals container py">
            <article className="">
                <h3 className="sectionTitle">Serbian Journal of <br/> Engineering Management</h3>
                <div className="grid grid-2-1">
                    <p className="text-justify">
                        {t("nauka.journal.about")}
                    </p>
                    <div className="text-center">
                        <img className="img" src="/image/vitraz.png" alt=""/>
                        <a className="btn btn_rounded btn_rounded-primary" target="_blank"
                           href="https://sjem.fim.edu.rs/index.php/sjem">
                            {t("buttons.readMore")}
                        </a>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default Journals;