import React from 'react';
import {motion} from "framer-motion";
import {Link, useSearchParams} from "react-router-dom";
import {opacityIn} from "../../libs/motionVarianst.js";
import TeamCard from "../../components/TeamCard.jsx";
import {routes} from "../../routes/routes.js";

function OsobljeKategorijaItem({data}) {
    const [params] = useSearchParams();

    const kategorija = params.get("category");

    return kategorija && data[kategorija].map((osoba, index) => {
        return <motion.div
            key={kategorija + index}
            variants={opacityIn}
            initial={"hidden"}
            animate={"visible"}>
            <Link to={routes.OSOBLJE_SINGLE.realPath(osoba.id)}>
                <TeamCard {...osoba} />
            </Link>
        </motion.div>;
    });

}

export default OsobljeKategorijaItem;