import React, {useState} from 'react';
import {Outlet} from "react-router-dom";
import Hero from "../components/Hero.jsx";
import {realFilePath} from "../libs/utils";


function DocumentsLayout(props) {
    const [title, setTitle] = useState("");
    return (
        <>
            <Hero title={title} background={realFilePath("hero_documents.jpg")}>
                <h1>{title}</h1>
            </Hero>
            <Outlet context={setTitle}/>
        </>
    );
}

export default DocumentsLayout;