import React, {useEffect, useState} from 'react';
import {useOutletContext, useParams} from "react-router-dom";
import {motion} from "framer-motion";
import {FaScaleBalanced} from "react-icons/fa6";
import {realFilePath} from "../../libs/utils";
import Documents from "../../services/Documents";

function DocumentsType(props) {
    const params = useParams();
    const setTitle = useOutletContext();
    const [documents, setDocuments] = useState(null);
    useEffect(() => {
        Documents.getByCategory(params.type)
            .then((res) => {
                setDocuments({
                    docs: res.data.docs,
                    description: res.data.category.description
                });
                setTitle(res.data.category.caption);
            })
            .catch((error) => {
                console.log(error);
            })
        ;

    }, [params]);

    return documents && (
        <section className="documents container py">
            <aside>
                <img src={realFilePath("side_documents.jpg")} alt="law"/>
            </aside>
            <article>
                {documents.description && <p>{documents.description}</p>}
                {documents.docs.length > 0 ?
                    documents.docs.map((doc, index) => {
                        return <motion.div key={params.type + index}
                                           initial={{
                                               opacity: 0.1
                                           }}
                                           animate={{
                                               opacity: 1
                                           }}
                                           transition={{
                                               duration: 0.5,
                                               bounce: 2,
                                               delay: index * 0.3
                                           }}>
                            <a href={realFilePath(doc.file)} target={"_blank"} className="listItem">
                                <span><FaScaleBalanced/></span>
                                <span>{doc.title}</span>
                            </a>
                        </motion.div>;
                    }) : <p>Trenutno nema dokumenata!</p>}
            </article>
        </section>
    );
}

export default DocumentsType;