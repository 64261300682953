import {Link} from "react-router-dom";
import parse, {domToReact} from "html-react-parser";
import React from "react";
import {FaFacebookF, FaInstagram, FaTiktok, FaViber} from "react-icons/fa6";
import {TfiYoutube} from "react-icons/tfi";

const icons = {
    facebook: <FaFacebookF/>,
    instagram: <FaInstagram/>,
    tiktok: <FaTiktok/>,
    viber: <FaViber/>,
    youtube: <TfiYoutube/>
};

const createOptions = (settings) => {
    return {
        replace: (domNode, index) => {
            if (domNode.name === "a" && domNode.attribs.href.startsWith("/")) {
                return <Link to={domNode.attribs.href} className={domNode.attribs.class}>{domToReact(domNode.children)}</Link>;
            } else if (domNode.name === "a") {
                return <a {...domNode.attribs}>{icons[settings.icon] || domNode.children[0].data}</a>;
            }
        }
    };
};

export function htmlWidget(widget) {
    let settings = JSON.parse(widget.settings || "[]");
    return parse(widget.content, createOptions(settings));
}