import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/sr'; // Importovanje srpske lokalizacije

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault("Europe/Belgrade");
dayjs.locale('sr'); // Postavljanje lokalizacije na srpski
const weekDays = ['Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota', 'Nedelja'];

function Calendar({year = 2024}) {
    const start = dayjs.tz(`${year}-10-01T00:00:00`, "Europe/Belgrade").startOf('day');
    const end = dayjs.tz(`${year + 1}-09-30T00:00:00`, "Europe/Belgrade").startOf('day');
    let days = [];
    for (let date = start; date.isBefore(end) || date.isSame(end); date = date.add(1, 'day')) {
        days.push(date);
    }
    const months = Array.from({length: 12}, (_, i) => ({
        name: dayjs().month(i).format('MMMM'),
        days: days.filter(day => day.month() === i)
    }));
    console.log(months);

    return (
        <div className="container py">
            <h2>{dayjs().format("YYYY.")} godina</h2>
            <div>
                <h1>{year} Calendar</h1>
                {months.map((month, index) => (
                    <div key={index}>
                        <h2>{month.name}</h2>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {month.days.map((day, idx) => (
                                <div key={idx} style={{width: '14.28%', border: '1px solid #ccc', padding: '10px'}}>
                                    {day.format('D')}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Calendar;