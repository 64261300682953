import React from 'react';
import Widget from "../../components/Widget/Widget";

function RasporedRada(props) {
    return <article>
        <h2 className="sectionTitle"><span className="firstUppercase">Raspored rada</span></h2>
        <Widget name={"plan_rada"} lang={"srb"}/>
    </article>;
}

export default RasporedRada;