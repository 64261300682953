import i18n from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {PRODUCTION_FE_URI} from "./libs/config";

const getCurrentHost =
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : PRODUCTION_FE_URI;
i18n
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: "srb",
        lng: "srb",
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: `${getCurrentHost}/locals/{{lng}}.json`
        },
        debug: false

    });

export default i18n;
