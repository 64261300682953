import React, {useEffect, useState} from 'react';
import Hero from "../../components/Hero.jsx";
import {useOutletContext, useParams} from "react-router-dom";
import Study from "../../services/Study.js";
import DOMParse from "html-react-parser";
import CourseLevel from "../../components/Study/CourseLevel.jsx";
import StudyProgramDetail from "./StudyProgramDetail";
import {realFilePath} from "../../libs/utils";

const colors = {
    closeBg: "#072248", openBg: "#072248",
    closeFont: "#ffffff", openFont: "#ffffff"
};

function CourseLevelSingle(props) {
    const [nivoStudija, setNivoStudija] = useState();
    const {level} = useParams();
    const locale = useOutletContext();
    const [expanded, setExpanded] = useState([0, 1]);
    useEffect(() => {
        let lang = locale ? locale : "srb";
        Study.getCourseLevelDetail(level, locale)
            .then((res) => {
                setNivoStudija(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [level, locale]);

    return nivoStudija && (
        <>
            <Hero background={realFilePath(nivoStudija.image)}
                  title={nivoStudija.title}>
                <h1>{nivoStudija.title}</h1>
            </Hero>
            <section className="courseLevel_single py bg-light">
                <article className="container">
                    <p>{DOMParse(nivoStudija.description)}</p>
                </article>
            </section>
            {nivoStudija.sp.length > 0 &&
                <section className="courseLevel_single_sp container bg-light">
                    <StudyProgramDetail studyPrograms={nivoStudija.sp}/>
                </section>}
            <CourseLevel/>
        </>
    );
}

export default CourseLevelSingle;