import {createSlice} from "@reduxjs/toolkit";


const configSlice = createSlice({
    name: "config",
    initialState: {
        lang: localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "",
        languageBarBtn: {
            show: true,
            relation: null
        },
        locale: "srb",
        navbar: {dark: false}
    },
    reducers: {
        setNavbarDark: (state, {payload}) => {
            state.navbar.dark = payload;
        },
        toggleLanguageBar: (state, {payload}) => {
            state.languageBarBtn.show = payload;
        },
        setLanguageRelation: (state, {payload}) => {
            let isShow = true;
            if (payload === null) {
                isShow = false;
            } else {
                state.languageBarBtn.relation = JSON.parse(payload);
                isShow = Object.keys(JSON.parse(payload)).length > 1;
            }
            state.languageBarBtn.show = isShow;
        },
        clearLanguageRelation: (state) => {
            state.languageBarBtn.relation = null;
            state.languageBarBtn.show = false;
        },
        setLang: (state, {payload}) => {
            state.lang = payload;
            localStorage.setItem("lang", payload);
        },

        setLocale: (state, {payload}) => {
            state.locale = payload;
        }
    }
});

export const {
    setLang,
    toggleLanguageBar,
    setLanguageRelation,
    clearLanguageRelation,
    setLocale,
    setNavbarDark
} = configSlice.actions;
export default configSlice.reducer;
