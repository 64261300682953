import React, {useEffect} from 'react';
import {useQuery} from "@tanstack/react-query";
import Izdavac from "../../services/Izdavac";
import {realFilePath} from "../../libs/utils";
import {useOutletContext} from "react-router-dom";
import {routes} from "../../routes/routes";
import {useDispatch} from "react-redux";
import {toggleLanguageBar} from "../../redux/configSlice";

function Udzbenici(props) {
    const {lang, setHeroTitle} = useOutletContext();
    const {data, isSuccess} = useQuery({
        queryKey: ["udzbenici"],
        queryFn: () => Izdavac.getUdzbenici()
    });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(toggleLanguageBar(false));
        setHeroTitle(routes.IZDAVAC_UDZBENICI.caption[lang]);
        return () => dispatch(toggleLanguageBar(true));
    }, []);
    let udzbenici = isSuccess ? data.data : [];

    return isSuccess && (
        <section className="izdavac udzbenici container py">
            {Object.keys(udzbenici).map((kategorija, index) => {
                return <article key={index}>
                    <h2 className="sectionTitle upperCase">{kategorija}</h2>
                    <div className="grid grid-col-4">
                        {udzbenici[kategorija].map((udzbenik) => {
                            return <div key={udzbenik.id} className="book_wrapper">
                                <div className="front" key={udzbenik.id}>
                                    <img src={realFilePath(udzbenik.image)} alt={udzbenik.naziv}/>
                                </div>
                                <div className="back">
                                    <div>
                                        <p>{udzbenik.autor}</p>
                                        <div>
                                            <h4>{udzbenik.naziv}</h4>
                                        </div>
                                        <p>{udzbenik.opis}</p>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                </article>;
            })}

        </section>
    );
}

export default Udzbenici;