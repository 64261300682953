import React, {useEffect, useState} from "react";
import {RemoveScroll} from "react-remove-scroll";
import {motion} from "framer-motion";
import {fadePreloader} from "../../libs/motionVarianst.js";
import {useSelector} from "react-redux";
import "./preloader.scss";

export function Preloader() {
    const {isShow} = useSelector(state => state.preloader);
    const [isRender, setIsRender] = useState(true);
    useEffect(() => {
        if (!isShow) {
            setTimeout(() => {
                setIsRender(false);
            }, 500);
        } else {
            setIsRender(true);

        }
    }, [isShow]);
    return isRender && <RemoveScroll removeScrollBar={false} enabled={isShow}>
        <motion.div
            initial={!isShow ? "hidden" : "visible"}
            animate={!isShow ? "hidden" : "visible"}
            className={"preloader"}
            variants={fadePreloader}
        >
            <div className="preloader-wrap">
                <div className="preloader-spinner">
                    <div className="preloader-dot1"></div>
                    <div className="preloader-dot2"></div>
                </div>
            </div>
        </motion.div>
    </RemoveScroll>;
}