import React, {Suspense} from 'react';
import {RouterProvider} from "react-router-dom";
import {router} from "../routes/router.jsx";
import {Preloader} from "../components/Preloader/Preloader.jsx";

function Router() {

    return <Suspense fallback={<Preloader/>}>
        <RouterProvider router={router}/>
    </Suspense>;

}

export default Router;
