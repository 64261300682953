import React, {useEffect, useState} from 'react';
import Accordion from "../../components/Accordion/Accordion.jsx";
import {FaCheck} from "react-icons/fa6";
import {Link, useParams} from "react-router-dom";
import Study from "../../services/Study.js";
import {realFilePath} from "../../libs/utils";
import {useDispatch} from "react-redux";
import {toggleLanguageBar} from "../../redux/configSlice";

function RasporedType() {
    const {slug} = useParams();
    const [expanded, setExpanded] = useState([0]);
    const [data, setData] = useState({
        nivoStudija: null, title: null
    });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(toggleLanguageBar(false));
        setExpanded([0]);
        Study.getRaspored(slug)
            .then((res) => {
                if (slug === "work") {
                    setData({
                        // nivoStudija: groupSchedule(res.data.schedule),
                        title: res.data.title
                    });
                } else {
                    setData({
                        nivoStudija: groupSchedule(res.data.schedule),
                        title: res.data.title
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return () => dispatch(toggleLanguageBar(true));
    }, [slug]);

    return <article>
        <h2 className="sectionTitle"><span className="firstUppercase">{data.title}</span></h2>
        {data.nivoStudija ? data.nivoStudija.map(({nivo, sp, nivoID}, index) => {
                return <Accordion title={nivo} expanded={expanded} key={index}
                                  setExpanded={setExpanded} indexKey={index}
                                  colors={{
                                      openBg: "#ef3139", closeFont: "#ffffff",
                                      closeBg: "#072248", openFont: "#ffffff"
                                  }}>
                    {
                        Object.values(sp).map(({naziv, godine}, index) => {
                            return (
                                <div key={index}>
                                    <h5><FaCheck/> {naziv}</h5>
                                    <ul>
                                        {godine.map((godina, index) => {
                                            return <li key={index}><Link to={realFilePath(godina.storeName)}>{godina.godinaStudija}</Link></li>;
                                        })}
                                    </ul>
                                </div>
                            );
                        })
                    }
                </Accordion>;
            })
            : <p>Trenutno nema objavljenog rasporeda.</p>
        }
    </article>;
}

export default RasporedType;

function groupSchedule(nivoStudija) {
    if (!nivoStudija) {
        return null;
    }
    let nivoS = {};
    nivoStudija.forEach((el) => {
        let {nivoID, nivo, spID, studijskiProgram, ...godina} = el;
        if (!nivoS[nivoID]) {
            nivoS[nivoID] = {
                nivoID, nivo, sp: {}
            };
        }
        if (!nivoS[nivoID].sp[spID]) {
            nivoS[nivoID].sp[spID] = {
                spID: spID,
                naziv: studijskiProgram,
                godine: []
            };
        }
        nivoS[nivoID].sp[spID].godine.push(godina);
    });
    return Object.values(nivoS);
}