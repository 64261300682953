import React from 'react';
import {toRoman} from "../../libs/romanNumber";
import {useTranslation} from "react-i18next";

function Predmeti({predmeti}) {
    const {t} = useTranslation("locals", {keyPrefix: "studije"});

    return (

        <table>
            <thead>
            <tr>
                <th>{t("plan.sifra")}</th>
                <th>{t("plan.nazivPredmeta")}</th>
                <th>{t("plan.status")}</th>
                <th>{t("plan.espb")}</th>
                <th>{t("plan.semestar")}</th>
            </tr>
            </thead>
            {Object.keys(predmeti).map(godina => {
                return <tbody key={godina}>
                <tr>
                    <th className="godina" colSpan={5}>{toRoman(godina)} {t("godina")}</th>
                </tr>
                {predmeti[godina].map(predmet => {
                    return <tr key={predmet.id} className={predmet.izborni ? "active" : ""}>
                        <td>{predmet.sifra}</td>
                        <td>{predmet.predmet}</td>
                        <td>{t(predmet.izborni ? "plan.izborni" : "plan.obavezni")}</td>
                        <td>{predmet.espb}</td>
                        <td>{predmet.semestar}</td>
                    </tr>;
                })}
                </tbody>;
            })}
        </table>

    );
}

export default Predmeti;