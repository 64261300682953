import React, {useLayoutEffect} from 'react';
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DOMParse from "html-react-parser";

function Biblioteka(props) {
    const {t} = useTranslation(null, {keyPrefix: "nauka.biblioteka"});
    const [title, setTitle] = useOutletContext();
    useLayoutEffect(() => {
        setTitle(t("title"));
    }, [t]);

    return (
        <section className="nauka container py">
            <article>
                {t("description", {returnObjects: true}).map((el, index) => {
                    return <p key={index}>{DOMParse(el)}</p>;
                })}
            </article>
            <article></article>
        </section>
    );
}

export default Biblioteka;