import React, {useEffect, useState} from 'react';
import {routes} from "../../routes/routes.js";
import Hero from "../../components/Hero.jsx";
import {Link, useOutletContext, useParams} from "react-router-dom";
import {realFilePath} from "../../libs/utils";
import Osoblje from "../../services/Osoblje";
import {useDispatch} from "react-redux";
import {setLanguageRelation, toggleLanguageBar} from "../../redux/configSlice";

function OsobljeSingle() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [osoblje, setData] = useState(null);
    const lang = useOutletContext();
    useEffect(() => {
        // dispatch(toggleLanguageBar(true));
        Osoblje.singleInfo(id)
            .then((res) => {
                console.log(res);
                setData(res.data);
                dispatch(setLanguageRelation(res.data.translate_relation));
            })
            .catch((error) => {
                console.log(error);
            });
        return () => dispatch(toggleLanguageBar(true));
    }, [lang]);


    return osoblje && (
        <>
            <Hero
                title={routes.NASTAVNO_OSOBLJE.caption.srb}
                background={realFilePath("untitled-2-1.jpg")}>
                <h1>Nastavno osoblje</h1>
            </Hero>
            <section className="osoblje_single container py grid grid-1-3">
                <article className="">
                    <img src={realFilePath(osoblje.image)} alt=""/>
                </article>
                <article>
                    <h2>{`${osoblje.rank} ${osoblje.title} ${osoblje.firstName} ${osoblje.lastName}`}
                        <span>{osoblje.status}</span>
                    </h2>
                    <p><span>Kontakt:</span>{osoblje.email}</p>
                    <ul className="obrazovanje">
                        {osoblje.obrazovanje.map((el, index) => {
                            return <li key={index}>
                                <span>{el.godina}</span>
                                <span>{el.tema}</span>
                                <span>{el.ustanova}</span>
                            </li>;
                        })}
                    </ul>
                    <p><span>Bibliografija:</span><Link to={osoblje.reference}>Reference</Link>
                    </p>
                </article>
            </section>
        </>
    );
}

export default OsobljeSingle;