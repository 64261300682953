import React, {useEffect, useRef, useState} from 'react';
import {Link, useOutletContext} from "react-router-dom";
import {routes} from "../../routes/routes.js";
import {motion, useInView} from "framer-motion";
import {fadeInOut} from "../../libs/motionVarianst.js";
import HomePage from "../../services/HomePage.js";
import TruncateMarkup from "react-truncate-markup";
import DOMParse from "html-react-parser";
import {useTranslation} from "react-i18next";
import {realFilePath} from "../../libs/utils";

function CourseLevel() {
    const ref = useRef(null);
    const inView = useInView(ref, {once: true});
    const [courseLevels, setCourseLevels] = useState([]);
    const locale = useOutletContext();
    useEffect(() => {
        HomePage.getCourseLevel(locale)
            .then((res) => {
                setCourseLevels(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [locale]);
    return (
        <section className="courseLevel py bg-light container">
            <motion.article ref={ref}
                            animate={inView ? "visible" : "hidden"}
                            initial="hidden"
                            variants={fadeInOut}
            >
                {courseLevels.length > 0 && courseLevels.map((course, index) => {
                    return <Course {...course}
                                   linkTo={routes.COURSE_LEVEL_SINGLE.realPath(course.slug, locale)}
                                   key={index}/>;
                })}
            </motion.article>

        </section>
    );
}

export function Course({image, title, description, linkTo}) {
    const {t} = useTranslation();
    return <div className="cardOverlay col-md-3">
        <div className="cardOverlay_thumnail">
            <img src={realFilePath(image)} alt={title}/>
        </div>
        <div className="cardOverlay_title">
            <h3>{title}</h3>
        </div>
        <div className="cardOverlay_overlay">
            <div>
                <div>
                    <TruncateMarkup
                        children={<div>{DOMParse(description)}</div>}
                        lines={3} ellipsis={"..."}/>
                    <div className="cardOverlay_footer">
                        <Link className="btn btn_rounded btn_rounded-active "
                              to={linkTo}>{t("buttons.readMore")}</Link>
                    </div>
                </div>
            </div>

        </div>

    </div>;
}


export default CourseLevel;