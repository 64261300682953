import React from 'react';

function Hero({children, background, title, style = {minHeight: "60vh"}}) {
    return <header className="hero" style={style}>
        <article className="hero_image">
            <img src={background} alt={title}/>
        </article>
        <article className="hero_content container">
            {children}
        </article>
    </header>
        ;
}

export default Hero;