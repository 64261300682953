import React, {useEffect, useState} from 'react';
import Hero from '../../components/Hero.jsx';
import {routes} from '../../routes/routes.js';
import Osoblje from "../../services/Osoblje.js";
import NenastavnoItem from "./NenastavnoItem.jsx";
import {realFilePath} from "../../libs/utils";
import {useOutletContext} from "react-router-dom";

function Nenastavno() {
    const lang = useOutletContext();
    const [data, setData] = useState(null);
    useEffect(() => {
        Osoblje.odbor("administrative_staff", lang)
            .then((res) => {
                setData({
                    osoblje: res.data.osoblje,
                    kategorije: res.data.kategorije
                });

            })
            .catch((error) => {
                console.log(error);
            });
    }, [lang]);


    let osoblje = data && data.osoblje;
    let kategorije = data && data.kategorije;

    return data && (
        <>
            <Hero
                title={routes.NENASTAVNO_OSOBLJE.path.srb}
                background={realFilePath("untitled-2-1.jpg")}>
                <h1>Nenastavno Osoblje</h1>
            </Hero>
            <section className="nenastavno wrapper container py">
                {kategorije.map((kat, index) => {
                    return <NenastavnoItem kategorija={kat} osoblje={osoblje} key={index} inline={false}/>;
                })
                }
            </section>
        </>
    );
}

export default Nenastavno;