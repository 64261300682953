import {FaFacebookF, FaInstagram, FaTiktok, FaViber} from "react-icons/fa6";
import React from "react";
import {TfiYoutube} from "react-icons/tfi";
import {Link} from "react-router-dom";
import parse, {domToReact} from "html-react-parser";

const icons = {
    facebook: <FaFacebookF/>,
    instagram: <FaInstagram/>,
    tiktok: <FaTiktok/>,
    viber: <FaViber/>,
    youtube: <TfiYoutube/>
};
const options = {
    replace: (domNode) => {
        if (domNode.name === "a" && domNode.attribs.href.startsWith("/")) {
            console.log(domNode);
            return <Link to={domNode.attribs.href}>{domToReact(domNode.children)}</Link>;
        }
    }
};

export function anchorWidget(widget) {
    let settings = JSON.parse(widget.settings);
    return parse(widget.content, options);
    return <a href={widget.content} target={settings.target}>{icons[settings.icon]}</a>;
}