import React, {useEffect, useState} from 'react';
import Widgets from "../../services/Widgets";
import {WidgetType} from "./WidgetType";

function Widget({name, lang = null, wrapperClass = null, wrapperElement = "div"}) {
    const [widgets, setWidgets] = useState(null);
    useEffect(() => {
        Widgets.getWidgets(name, lang)
            .then((res) => {
                setWidgets(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [lang]);

    return widgets && widgets.map((widget, index) => {
        return React.createElement(wrapperElement, {
            className: wrapperClass,
            key: index
        }, WidgetType(widget, widget.type));
    });
}


export default Widget;