import axios from "axios";

class Study {
    static getCourseLevels = () => axios.get(`/course_level`);
    static getCourseLevelDetail = (level, locale) => axios.get(`/course_level/${level}/${locale}`);
    static getStudyProgram = (id) => axios.get(`/study/program/${id}`);
    static getPredmeti = id => axios.get(`/study/program/${id}/predmeti`);
    static getRaspored = (slug) => axios.get(`/raspored/${slug}`);
}

export default Study;