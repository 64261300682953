import React from 'react';
import {Outlet, useParams} from "react-router-dom";
import Hero from "../../components/Hero.jsx";
import LastNewsSidebar from "../../components/Vesti/LastNewsSidebar.jsx";
import {realFilePath} from "../../libs/utils";
import RasporedRada from "./RasporedRada";

function RasporedLayout() {
    const {slug} = useParams();
    return (
        <>
            <Hero title={"Raspored predavanja"} background={realFilePath("hero.jpg")}>
                <h1>Raspored</h1>
            </Hero>
            <section className="raspored newsSingle container py">
                {slug === "work" ? <RasporedRada/> : <Outlet/>}
                <LastNewsSidebar/>
            </section>
        </>
    );
}

export default RasporedLayout;