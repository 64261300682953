// https://code.pieces.app/blog/react-form-validation-formik-yup
import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {string} from "yup";
import {toast} from "react-toastify";
import {FaEnvelope} from "react-icons/fa6";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function KontaktForma({t}) {
    const formik = useFormik({
        initialValues: {
            fullName: "", email: "", subject: "", message: ""
        },
        validationSchema: Yup.object().shape({
            fullName: string().required(t("errors.fullName")),
            email: string().matches(emailRegex, t("errors.emailInvalid")).required(t("errors.emailRequired")),
            subject: string().required(t("errors.subject")),
            message: string().required(t("errors.message"))
        }),
        onSubmit: (values, {resetForm}) => {
            resetForm();
            toast.success(t("notify"), {
                icon: <FaEnvelope/>
            });
        }
    });
    const showError = (name) => formik.touched[name] && formik.errors[name] ?
        <span>{formik.errors[name]}</span> : null;

    return (
        <form className="contactForm" onSubmit={formik.handleSubmit}>
            <div className="inputGroup">
                <input type="text" id="fullName" name="fullName" placeholder={""}
                       onChange={formik.handleChange} value={formik.values.fullName}
                />
                <label htmlFor="fullName">{t("input.ime")}</label>
                {showError("fullName")}
            </div>
            <div className="inputGroup">
                <input type="text" id="email" name="email" placeholder={""}
                       onChange={formik.handleChange} value={formik.values.email}
                />
                <label htmlFor="email">{t("input.email")}</label>
                {showError("email")}
            </div>
            <div className="inputGroup">
                <input type="text" id="subject" name="subject" placeholder={""}
                       onChange={formik.handleChange} value={formik.values.subject}/>
                <label htmlFor="subject">{t("input.naslov")}</label>
                {showError("subject")}
            </div>
            <div className="inputGroup">
                <textarea id="message" name="message" placeholder={""} cols="30"
                          rows="10" onChange={formik.handleChange}
                          value={formik.values.message}></textarea>
                <label htmlFor="message">{t("input.poruka")}</label>
                {showError("message")}
            </div>
            <button className="btn btn-primary" type="submit">{t("input.dugme")}</button>

        </form>
    );
}

export default KontaktForma;