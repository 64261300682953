import {htmlWidget} from "./htmlWidget";
import {anchorWidget} from "./anchorWidget";
import wysiwygWidget from "./wysiwygWidget";

export function WidgetType(widget, type) {
    switch (type) {
        case "html":
            return htmlWidget(widget);
        case "anchor":
            return anchorWidget(widget);
        case "wysiwyg":
            return wysiwygWidget(widget);
    }
}