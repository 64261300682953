import React from 'react';
import {useTranslation} from "react-i18next";

function BottomBar() {
    const {t} = useTranslation();
    return (
        <section className="bottomBar">
            <p>&copy; {t("copyright")}</p>
        </section>
    );
}

export default BottomBar;