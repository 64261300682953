import React, {useLayoutEffect, useState} from 'react';

function UseWindowScroll() {
    const [position, setPosition] = useState({x: window.scrollX, y: window.scrollY});
    useLayoutEffect(() => {
        window.addEventListener("scroll", checkPosition);
        return () => {
            window.removeEventListener("scroll", checkPosition);
        };
    }, []);

    function checkPosition() {
        setPosition({
            x: window.scrollX, y: window.scrollY
        });
    }

    return [position.x, position.y];
}

export default UseWindowScroll;