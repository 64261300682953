import React from 'react';
import {FaGlobe} from "react-icons/fa6";
import Widget from "../Widget/Widget";

// FACEBOOK
// INSTAGRAM
// TIKTOK
// VIBER
// YOUTUBE
const iconMap = {};

function Social() {
    return (
        <div className="social">
            <div className="circle">
                <FaGlobe/>
            </div>
            <div className="icons">
                <ul>
                    <Widget name={"social"} wrapperElement={"li"}/>
                </ul>
            </div>
        </div>
    );
}

export default Social;