import React from 'react';
import {useTranslation} from "react-i18next";
import Widget from "./Widget/Widget";

function Footer({lang}) {
    const {t} = useTranslation(null, {keyPrefix: "footer"});

    return (
        <footer className="footer">
            <article className="footer_wrapper container py">
                <Widget name={"footer"}
                        lang={lang}
                        wrapperClass={"footer_wrapper_col"}
                        wrapperElement={"div"}/>
            </article>
        </footer>
    );
}

export default Footer;