export const fadeInOut = {
    visible: {opacity: 1, scale: 1, transition: {duration: 1}},
    hidden: {opacity: 0, scale: .8, transition: {duration: 1}}
};

export const opacityIn = {
    visible: {opacity: 1, transition: {duration: 1}},
    hidden: {opacity: 0, transition: {duration: 1}}
};

export const fromTop = {
    visible: {opacity: 1, y: 0},
    hidden: {opacity: 0, y: -100}
};


export const fadePreloader = {
    visible: {opacity: 1, transition: {duration: 1}},
    hidden: {opacity: 0, transition: {duration: 1}}
};