import {PRODUCTION_BE_URI} from "./config";


export const realFilePath = (fileName) => {
    const regex = /^http/i;
    if (regex.test(fileName)) {
        return fileName;
    }
    return process.env.NODE_ENV === "development" ? `http://localhost:4000/uploads/${fileName}` : `${PRODUCTION_BE_URI}/uploads/${fileName}`;
};
