import React, {useEffect, useState} from 'react';
import Hero from '../../components/Hero';
import {routes} from '../../routes/routes';
import Osoblje from "../../services/Osoblje.js";
import {Link, useOutletContext, useSearchParams} from "react-router-dom";
import {realFilePath} from "../../libs/utils";
import OsobljeKategorijaItem from "./OsobljeKategorijaItem";

function NastavnoOsoblje() {
    const [params, setParams] = useSearchParams();
    const lang = useOutletContext();
    const [data, setData] = useState(null);
    const kategorijaSlug = params.get("category");

    useEffect(() => {
        setData(null);
        Osoblje.odbor("academic_staff", lang)
            .then((res) => {
                setData({
                    osoblje: res.data.osoblje,
                    kategorije: res.data.kategorije
                });
                setParams({category: res.data.kategorije[0]});
            })
            .catch((error) => {
                console.log(error);
            });
    }, [lang]);

    let osoblje = data && data.osoblje;

    return data && osoblje && (
        <>
            <Hero
                title={routes.NASTAVNO_OSOBLJE.caption.srb}
                background={realFilePath("untitled-2-1.jpg")}>
                <h1>Nastavno osoblje</h1>
            </Hero>
            <section className="nastavno wrapper container py">
                <div className="nastavno_kategorije">
                    {data.kategorije.map((kategorija, index) => {
                        return <Link
                            preventScrollReset={true}
                            key={index}
                            className={
                                kategorija === kategorijaSlug ? 'active' : 'inactive'
                            }
                            to={routes.NASTAVNO_OSOBLJE_KATEGORIJA.realPath(kategorija, lang)}
                        >
                            {kategorija}
                        </Link>;
                    })}
                </div>
                <div className="dekanat">
                    <div className="teamCard_wrapper">
                        <OsobljeKategorijaItem data={osoblje}/>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NastavnoOsoblje;
