import React, {useState} from 'react';
import {Outlet} from "react-router-dom";
import Hero from "../../components/Hero.jsx";
import {realFilePath} from "../../libs/utils";

function NaukaLayout(props) {
    // const {t} = useTranslation(null, {keyPrefix: "nauka.ebaza"});
    const [title, setTitle] = useState();
    return (
        <>
            <Hero background={realFilePath("ebaza_hero.jpg")} title={"ebsco baza"}>
                <h1>{title}</h1>
            </Hero>
            <Outlet context={[title, setTitle]}/>
        </>
    );
}

export default NaukaLayout;