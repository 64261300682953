import React, {useRef} from 'react';
import {motion, useInView} from "framer-motion";
import {fadeInOut} from "../libs/motionVarianst.js";
import {useTranslation} from "react-i18next";
import {realFilePath} from "../libs/utils";
import {useOutletContext} from "react-router-dom";
import Widget from "./Widget/Widget";

function Intro() {
    const locale = useOutletContext();
    const ref = useRef(null);
    const inView = useInView(ref, {once: true});
    const {t} = useTranslation();
    return (
        <motion.section ref={ref} className="intro container py" style={{overflowX: "hidden"}}
                        animate={inView ? "visible" : "hidden"}
                        variants={fadeInOut}
                        initial={"hidden"}>
            <article>
                <div>
                    <img src={realFilePath("dekan-scaled.jpg")} alt="dekan"/>
                </div>
            </article>
            <article>
                <div>
                    <Widget lang={locale} name={"rec_dekana"}/>
                    {/*{DOMParse(t("home.intro"))}*/}
                </div>
            </article>

        </motion.section>

    );
}

export default Intro;