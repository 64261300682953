import React, {useEffect} from 'react';
import Image503 from "./Image503";
import {Link} from "react-router-dom";
import {routes} from "../../../routes/routes";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setNavbarDark} from "../../../redux/configSlice";

function Error503() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {lang} = useSelector(state => state.configStore);
    useEffect(() => {
        dispatch(setNavbarDark(true));
        return () => {
            dispatch(setNavbarDark(false));
        };
    }, []);
    return (
        <section className="errorPage-404 container">
            <article>
                <Image503/>
                <h2>{t("errors.503.title")}</h2>
                <h6>{t("errors.503.description")}</h6>
                <Link className="btn btn_rounded btn_rounded-primary"
                      to={routes.HOME.realPath(lang)}>{t("errors.button")}</Link>
            </article>
        </section>
    );
}

export default Error503;