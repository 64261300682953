import React from 'react';
import Router from "./Router.jsx";
import {HelmetProvider} from "react-helmet-async";
import ReactQueryProvider from "./ReactQueryProvider.jsx";

import {Provider} from "react-redux";
import {store} from "../redux/store.js";

function GlobalProvider({children}) {

    return (
        <>
            <HelmetProvider>
                <ReactQueryProvider>
                    <Provider store={store}>
                        <Router/>
                    </Provider>
                </ReactQueryProvider>
            </HelmetProvider>
        </>
    );
}

export default GlobalProvider;