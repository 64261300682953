import React from 'react';

function GoogleMap(props) {
    return (
        <>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5662.636053155309!2d20.436314!3d44.794707!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6fe22b1459ab%3A0xd099c42ce3b5c928!2sBulevar%20vojvode%20Mi%C5%A1i%C4%87a%2043%2C%20Beograd%2C%20Serbia!5e0!3m2!1sen!2sus!4v1712215771220!5m2!1sen!2sus"
                style={{width: "100%", border: 0}} height="450" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </>
    );
}

export default GoogleMap;