import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import srb from "../../assets/srb.svg";
import en from "../../assets/en.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {clearLanguageRelation} from "../../redux/configSlice";

export const validLocale = ["srb", "en"];

function LanguageBar({locale}) {
    const {i18n} = useTranslation();
    const {languageBarBtn} = useSelector(state => state.configStore);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        if (validLocale.includes(locale)) {
            i18n.changeLanguage(locale);
        } else {
            i18n.changeLanguage("srb");
        }
    }, [locale]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        // TODO promeniti jezik u redux i u localStorage, nakon promene redirect na odgovarajucu rutu
        let redirected = "";
        let relation = languageBarBtn.relation;

        if (lng === "srb") {
            redirected = location.pathname.replace("/" + locale, "");
        } else {
            redirected = "/" + lng + location.pathname.replace(/\/$/, "");
        }

        if (relation) {
            let currentLng = locale ? locale : "srb";
            redirected = redirected.replace(relation[currentLng], relation[lng]);
            dispatch(clearLanguageRelation());
        }

        navigate(redirected ? redirected : "/");

    };


    return languageBarBtn.show && (
        <div>
            {locale === "en" ?
                <img onClick={() => changeLanguage("srb")} src={srb} alt=""/>
                :
                <img onClick={() => changeLanguage("en")} src={en} alt=""/>
            }
        </div>
    );
}

export default LanguageBar;