import React from 'react';
import TruncateMarkup from 'react-truncate-markup';
import {Link, useOutletContext} from 'react-router-dom';
import dayjs from 'dayjs';
import {useTranslation} from "react-i18next";
import {routes} from "../routes/routes";
import {realFilePath} from "../libs/utils";

function NewsCard({thumbnail, title, children, news}) {

    return (
        <div className="newsCard cardOverlay">
            <div className="thumbnail">
                <img src={realFilePath(news.thumbnail)} alt={news.naslov}/>
            </div>

            <div className="cardOverlay_title">
                <TruncateMarkup
                    children={<h4>{news.naslov}</h4>}
                    lines={2}
                    lineHeight={20}
                    ellipsis={'...'}
                />
            </div>
            <div className="cardOverlay_overlay">
                <div>
                    <TruncateMarkup
                        children={<div>{children}</div>}
                        lines={2}
                        lineHeight={25}
                        ellipsis={'...'}
                    />
                    <div className="cardOverlay_footer">
                        <ReadMore
                            id={news.id}
                            date={dayjs(news.createdAt).format('DD.MM.YYYY.')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ReadMore({date, id}) {
    const {t} = useTranslation("locals", {keyPrefix: "news"});
    const lang = useOutletContext();
    return (
        <div className="card_body-bottom">
            <Link
                className="btn btn_rounded btn_rounded-active"
                to={routes.NEWS_SINGLE.realPath(id, lang)}>
                {t("card.btn")}
            </Link>
            <p>{date}</p>
        </div>
    );
}

export default NewsCard;
