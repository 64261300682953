import React, {useLayoutEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import {realFilePath} from "../../libs/utils";

function Ebaza(props) {
    const {t} = useTranslation(null, {keyPrefix: "nauka.ebaza"});
    const [title, setTitle] = useOutletContext();
    useLayoutEffect(() => {
        setTitle(t("title"));
    }, [t]);
    return (
        <section className="nauka container py">
            <article className="grid grid-1-1">
                <div>
                    <p>{t("ebscohost")}</p>
                    <a className="btn btn_rounded btn_rounded-primary" href={"https://search.ebscohost.com/Login.aspx"}
                       target="_blank">{t("prijava")}</a>
                </div>
                <div>
                    <img className="img" src={realFilePath("ebsco_logoehost.png")} alt=""/>
                </div>
            </article>
            <article className="grid grid-1-1">
                <div>
                    <p>{t("ebsco")}</p>
                    <a className="btn btn_rounded btn_rounded-primary" href={"https://www.ebsco.com"}
                       target="_blank">www.ebsco.com</a>
                </div>
                <div>
                    <img className="img img-center img-circle img-scale-h200 " src={realFilePath("ebsco_logo.png")}
                         alt=""/>
                </div>
            </article>
            <article className="grid grid-1-1">
                <div>
                    <p>{t("ojspkp")}</p>
                    <a className="btn btn_rounded btn_rounded-primary" href={"https://sjem.fim.edu.rs"}
                       target="_blank">www.sjem.fim.edu.rs</a>
                </div>
                <div>
                    <a href="https://pkp.sfu.ca/software/ojs/" target="_blank">
                        <img className="img img-center img-scale-h200"
                             src={realFilePath("ojs_brand.png")} alt=""/>
                    </a>
                </div>
            </article>
        </section>
    );
}

export default Ebaza;