import {createBrowserRouter} from "react-router-dom";
import RootLayout from "../RootLayout.jsx";
import {srbRoutes} from "./srbRoutes";
import {enRoutes} from "./enRoutes";

export const lang = localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "srb";
export const router = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout/>,
        children: [
            // SRB
            ...srbRoutes,
            // EN
            ...enRoutes
        ]
    }

]);