import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const closeLoader = createAsyncThunk(
    "preloader/closeLoader",
    async (payload, thunkAPI) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
);

const preloaderSlice = createSlice({
    name: "preloader",
    initialState: {
        isShow: true,
        disable: false
    },
    reducers: {
        showLoader: (state, action) => {
            if (!state.disable) {
                state.isShow = true;
            }
        },
        setDisable: (state, {payload}) => {
            state.disable = payload;
        },
        toggleLoader: (state, {payload}) => {
            if (!state.disable) {
                let delay = payload.delay ? payload.delay : 500;
                if (state) {
                    state.isShow(true);
                } else {
                    state.isShow(false);
                    setTimeout(() => {
                    }, delay);
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(closeLoader.fulfilled, (state, action) => {
            state.isShow = false;
        });

    }
});
export const {showLoader, toggleLoader, setDisable} = preloaderSlice.actions;
export default preloaderSlice.reducer;