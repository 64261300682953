import {AnimatePresence, motion} from "framer-motion";
import {FaMinusSquare, FaPlusSquare} from "react-icons/fa";

function Accordion({
                       title,
                       indexKey,
                       setExpanded,
                       children,
                       footerElement = null,
                       expanded,
                       colors = {
                           openBg: "#072248", closeBg: "#ffffff",
                           openFont: "#ffffff", closeFont: "#072248"
                       },
                       footerStyle = {},
                       autoClose = true
                   }) {
    let isOpen = expanded.includes(indexKey);
    let activeClass = expanded.includes(indexKey) ? "active" : "";
    const toggleHandler = () => {
        let tempExp = [...expanded];
        if (autoClose) {
            if (tempExp.includes(indexKey)) {
                setExpanded([]);
            } else {
                setExpanded([indexKey]);
            }
        } else {
            if (expanded.includes(indexKey)) {
                setExpanded(() => expanded.filter(el => el !== indexKey));
            } else {
                setExpanded([...expanded, indexKey]);
            }
        }
    };
    return (
        <article className="accordion">
            <motion.div
                initial={false}
                animate={{
                    backgroundColor: isOpen ? colors.openBg : colors.closeBg,
                    color: isOpen ? colors.openFont : colors.closeFont
                }}
                className="accordion_title" onClick={toggleHandler}>
                <h4>{title}</h4>
                <span className={activeClass}>{!isOpen ? <FaPlusSquare/> : <FaMinusSquare/>}</span>
            </motion.div>

            <AnimatePresence initial={false}>
                {isOpen && <motion.div
                    initial={"collapsed"}
                    animate={"open"}
                    exit={"collapsed"}
                    variants={{
                        open: {height: "auto"},
                        collapsed: {height: 0}
                    }}
                    transition={{duration: 0.8}}
                    className="accordion_body"
                >
                    <motion.div
                        variants={{
                            collapsed: {scale: 1},
                            open: {scale: 1}
                        }}
                        transition={{duration: 0.8}}>
                        {children}
                        {footerElement && <div className="accordion_footer" style={footerStyle}>
                            {footerElement}
                        </div>}
                    </motion.div>
                </motion.div>}
            </AnimatePresence>
        </article>
    );
}

export default Accordion;