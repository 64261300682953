import parse, {domToReact} from "html-react-parser";

function WysiwygWidget(widget) {
    return parse(widget.content, {
        replace: (domNode) => {
            if (domNode.name === "ol") {
                return <ul>
                    {domToReact(domNode.children)}
                </ul>;
            }
        }
    });
}

export default WysiwygWidget;