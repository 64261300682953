import React, {useState} from 'react';
import {Outlet, useOutletContext} from "react-router-dom";
import Hero from "../../components/Hero";
import SEO from "../../components/SEO";
import {realFilePath} from "../../libs/utils";

function PublishLayout() {
    const [heroTitle, setHeroTitle] = useState("");
    const lang = useOutletContext();
    return (
        <>
            <SEO title={heroTitle}/>
            <Hero background={realFilePath("hero_izdavac.jpg")} title={"Izdavac"}>
                <h1>{heroTitle}</h1>
            </Hero>
            <Outlet context={{lang, setHeroTitle}}/>
        </>
    );
}

export default PublishLayout;