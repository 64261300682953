import React, {useEffect} from 'react';
import {Outlet, ScrollRestoration} from "react-router-dom";
import axios from "axios";
import "./styles/index.scss";
import {useDispatch} from "react-redux";


if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "http://localhost:4000/fe";
} else {
    axios.defaults.baseURL = "https://fim-be.zile028.com/fe";
}

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (config.method === 'post') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

function RootLayout() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!localStorage.hasOwnProperty("lang")) {
            localStorage.setItem("lang", "srb");
            // dispatch(setLang("srb"));
        }
    }, []);

    return <>
        <ScrollRestoration/>
        <Outlet/>
    </>;
}

export default RootLayout;