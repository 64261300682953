import React, {useEffect} from 'react';
import Navbar from "../components/Navbar/Navbar.jsx";
import {Outlet, useLocation} from "react-router-dom";
import Footer from "../components/Footer.jsx";
import BottomBar from "../components/BottomBar.jsx";
import {Preloader} from "../components/Preloader/Preloader.jsx";
import {useDispatch, useSelector} from "react-redux";
import {closeLoader, showLoader} from "../redux/preloaderSlice.js";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Social from "../components/Social/Social";
import {setLang} from "../redux/configSlice";

function ClientLayout({lang}) {
    const dispatch = useDispatch();
    const {excludePath} = useSelector(state => state.preloader);
    let location = useLocation();
    useEffect(() => {
        dispatch(showLoader());
        dispatch(setLang(lang));
        setTimeout(() => {
            dispatch(closeLoader());
        }, 300);
    }, [location.pathname]);

    return lang && (
        <>
            <Preloader/>
            <Navbar lang={lang}/>
            <Outlet context={lang}/>
            <Footer lang={lang}/>
            <BottomBar/>
            <Social/>
            <ToastContainer/>
        </>
    );
}

export default ClientLayout;