import React from 'react';
import {Outlet, useOutletContext} from "react-router-dom";
import GoogleMap from "../../components/GoogleMap.jsx";
import CourseLevel from "../../components/Study/CourseLevel.jsx";

function Admission() {
    const lang = useOutletContext();
    return (
        <>
            <Outlet context={lang}/>
            <CourseLevel/>
            <GoogleMap/>
        </>
    );
}

export default Admission;