import React, {useEffect} from 'react';
import Hero from "../../components/Hero.jsx";
import {useMutation} from "@tanstack/react-query";
import Study from "../../services/Study.js";
import {useParams} from "react-router-dom";
import DOMParse from "html-react-parser";
import {useTranslation} from "react-i18next";
import {realFilePath} from "../../libs/utils";
import {useDispatch} from "react-redux";
import {setLanguageRelation, toggleLanguageBar} from "../../redux/configSlice";
import Predmeti from "./Predmeti";

function StudyProgram() {
    const {t} = useTranslation("locals", {keyPrefix: "studije"});
    const dispatch = useDispatch();
    const params = useParams();
    const {data: sp, isSuccess: spSuccess, mutate: spMutate} = useMutation({
        mutationKey: ["study_program"],
        mutationFn: () => Study.getStudyProgram(params.id),
        onSuccess: (data) => {
            dispatch(setLanguageRelation(data.data.translate_relation));
            console.log(data);
        }
    });
    const {data: predmeti, isSuccess: predmetiSuccess, mutate: predmetiMutate} = useMutation({
        mutationKey: ["predmeti"],
        mutationFn: () => Study.getPredmeti(params.id),
        onSuccess: (data) => {
            console.log(data);
            console.log(Object.entries(data.data));

        }
    });
    useEffect(() => {
        spMutate();
        predmetiMutate();
        return () => dispatch(toggleLanguageBar(true));
    }, [params]);
    return predmetiSuccess && spSuccess && (
        <>
            <Hero title="Studijski program" background={realFilePath("osnovne_studije.jpg")}>
                <h1>{sp.data.naziv}</h1>
            </Hero>
            <section className="studyProgram container grid grid-3-1 py">
                <article>
                    {(!sp.data.cilj && !sp.data.opis && !sp.data.ishod) && <p>{t("detalji")}</p>}
                    {sp.data.opis && <p>{DOMParse(sp.data.opis)}</p>}
                    {sp.data.cilj &&
                        <>
                            <h3>{t("cilj")}</h3>
                            <p>{DOMParse(sp.data.cilj)}</p>
                        </>
                    }
                    {sp.data.ishod &&
                        <>
                            <h3>{t("ishod")}</h3>
                            <p>{DOMParse(sp.data.ishod)}</p>
                        </>
                    }
                </article>
                <aside>
                    <ul>
                        <li><span>{t("espb")}:</span><span>{sp.data.espb}</span></li>
                        <li><span>{t("trajanje")}:</span><span>{sp.data.trajanje}</span></li>
                        <li><span>{t("zvanje")}:</span><span>{sp.data.zvanje}</span></li>
                        <li><span>{t("polje")}:</span><span>{sp.data.polje}</span></li>
                        <li><span>{t("akreditovan")}:</span><span>{sp.data.akreditovan}</span></li>
                    </ul>
                </aside>
            </section>
            <section className="studyProgram table container py-last">
                {predmeti.data ? <Predmeti predmeti={predmeti.data}/> :
                    <p className="badge badge-warning">{t("predmetiTabela")}</p>}
            </section>
        </>
    );
}

export default StudyProgram;