import React, {useEffect, useState} from 'react';
import Hero from '../../components/Hero.jsx';
import {routes} from '../../routes/routes.js';
import TeamCard from '../../components/TeamCard.jsx';
import Osoblje from "../../services/Osoblje.js";
import {realFilePath} from "../../libs/utils";
import NenastavnoItem from "./NenastavnoItem";
import {useOutletContext} from "react-router-dom";

function DekanskiKolegijum() {
    const lang = useOutletContext();
    const [data, setData] = useState(null);
    useEffect(() => {
        Osoblje.odbor("management", lang)
            .then((res) => {
                setData({
                    osoblje: res.data.osoblje,
                    kategorije: res.data.kategorije
                });
            })
            .catch((error) => {
                console.log(error);

            });
    }, [lang]);

    let osoblje = data && data.osoblje;
    let kategorije = data && data.kategorije;

    return data && (
        <>
            <Hero
                title={routes.NASTAVNO_OSOBLJE.caption.srb}
                background={realFilePath("untitled-2-1.jpg")}>
                <h1>Dekanski Kolegijum</h1>
            </Hero>

            <section className="nenastavno wrapper container py">
                {kategorije.map((kat, index) => {
                    return <NenastavnoItem kategorija={kat} osoblje={osoblje} key={index}/>;
                })
                }
            </section>

            {/*TODO OBRISATI*/}
            {false && <section className="dekanat container py">
                <div className="teamCard_wrapper">
                    {kategorije.map((kat, index) => {
                        return (
                            <div key={index}>
                                {osoblje[kat].map((val, item) => {
                                    return <TeamCard
                                        key={item + 10} id={val.id}
                                        {...val}
                                        funkcija={val.pozicija}
                                        index={item}/>;
                                })
                                }
                            </div>
                        );
                    })}
                </div>
            </section>}
        </>
    );
}

export default DekanskiKolegijum;
