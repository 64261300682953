import React, {useEffect, useState} from 'react';
import Hero from "../../components/Hero.jsx";
import {routes} from "../../routes/routes.js";
import Accordion from "../../components/Accordion/Accordion.jsx";
import DOMParse from "html-react-parser";
import {realFilePath} from "../../libs/utils";
import Admission from "../../services/Admission";
import {useOutletContext, useParams} from "react-router-dom";
import Error204 from "../Errors/Error204/Error204";

function AdmissionRules() {
    const [expanded, setExpanded] = useState([0]);
    const [accRef, setAccRef] = useState(0);
    const lang = useOutletContext();
    const [rules, setRules] = useState([]);
    const {slug} = useParams();
    useEffect(() => {
        console.log(slug);
        Admission.getRules(slug, lang)
            .then((res) => {
                setRules(res.data);
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [slug, lang]);


    return (
        <>
            <Hero title={routes.ENROLLMENT_LEVEL.caption[slug][lang]}
                  background={realFilePath("hero_upis.jpg")}>
                <h1>{routes.ENROLLMENT_LEVEL.caption[slug][lang]}</h1>
            </Hero>
            <section className="enrolment container py">
                {rules.length > 0 ? rules.map((info, index) => {
                    return <Accordion key={info.id} title={info.uslov} indexKey={index}
                                      setExpanded={setExpanded}
                                      expanded={expanded}
                    >
                        {DOMParse(info.opis)}
                    </Accordion>;
                    //     TODO Napraviti komponentu koja ce se prikazivati kada nema podataka
                }) : <Error204/>}
            </section>
        </>
    );
}

export default AdmissionRules;