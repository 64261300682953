import React from 'react';
import Hero from '../components/Hero';
import {routes} from '../routes/routes';
import {useQuery} from "@tanstack/react-query";
import Info from "../services/Info.js";
import DOMParse from "html-react-parser";
import {useTranslation} from "react-i18next";
import GoogleMap from "../components/GoogleMap.jsx";
import {realFilePath} from "../libs/utils";

function AboutFakultet() {
    const {t} = useTranslation();
    const {data} = useQuery({
        queryKey: ["info"],
        queryFn: () => Info.getInfo()
    });
    let info = data?.data;
    return info && (
        <>
            <Hero title={routes.ABOUT.caption.srb}
                  background={realFilePath("fim-interior-1-1-1024x352.jpg")}
            >
                <h1>{t("onama.title")}</h1>
            </Hero>
            <section className="about py container">
                {info.map(el => {
                        return <article className="about_wrapper">
                            <h2>{el.info_title}</h2>
                            <div className="about_description">
                                {DOMParse(el.info_text)}
                            </div>
                        </article>;
                    }
                )}
            </section>
            <GoogleMap/>
        </>
    );
}

export default AboutFakultet;