import React, {Suspense} from 'react';
import {Outlet, useOutletContext} from "react-router-dom";
import {Preloader} from "../components/Preloader/Preloader.jsx";

function About() {
    const lang = useOutletContext();
    return (
        <>
            {/* Ovde hendlujemo sve pod-page-eve za About */}
            <Suspense fallback={<Preloader/>}>
                <Outlet context={lang}/>
            </Suspense>
        </>
    );
}

export default About;