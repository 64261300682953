import React, {useEffect} from 'react';
import TruncateMarkup from "react-truncate-markup";
import dayjs from "dayjs";
import {useQuery} from "@tanstack/react-query";
import NewsService from "../../services/NewsService.js";
import {Link, useOutletContext} from "react-router-dom";
import {motion} from "framer-motion";
import {routes} from "../../routes/routes";
import {realFilePath} from "../../libs/utils";
import {useTranslation} from "react-i18next";

function LastNewsSidebar() {
    const locale = useOutletContext();
    const {t} = useTranslation();
    const {data, refetch} = useQuery({
        queryKey: ["lastNews"], queryFn: () => NewsService.getNews(5, 1, locale)
    });
    useEffect(() => {
        refetch();
    }, [locale]);
    let lastNews = data?.data.lastNews;
    return lastNews && (
        <aside className="lastNews_sidebar">
            <h3>{t("home.latestNews")}</h3>
            <div className="wrapper">
                {lastNews.map((news, index) => {
                    return <Link key={news.id} to={routes.NEWS_SINGLE.realPath(news.id, locale)}>
                        <motion.div
                            initial={{opacity: 0, translateY: "-20"}}
                            animate={{
                                opacity: 1, translateY: "0"
                            }}
                            transition={{
                                duration: .3,
                                bounce: 2,
                                delay: 0.3 * index
                            }}
                            className="latestNews">
                            <div className="thumbnail">
                                <img src={realFilePath(news.thumbnail)} alt=""/>
                            </div>
                            <div className="content">
                                <span>{dayjs(news.createdAt).format("DD.MM.YYYY.")}</span>
                                <TruncateMarkup lines={2} ellipsis={"..."}>
                                    <p>{news.naslov}</p>
                                </TruncateMarkup>
                            </div>
                        </motion.div>
                    </Link>;
                })}
            </div>
        </aside>
    );
}

export default LastNewsSidebar;